import React from 'react'

export const BDF170 = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1259"
    height="690"
    viewBox="0 0 1259 690"
    {...props}
  >
    <defs>
      <pattern
        id="pattern"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 125 145"
      >
        <image
          width="125"
          height="145"
          href="../bdf-170/PA01.161.13E.0111_18.png"
        />
      </pattern>
      <pattern
        id="pattern-2"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 197 230"
      >
        <image
          width="197"
          height="230"
          href="../bdf-170/PA01.161.13E.0111_17.png"
        />
      </pattern>
      <pattern
        id="pattern-3"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 257 263"
      >
        <image
          width="257"
          height="263"
          href="../bdf-170/PA01.161.13E.0111_13.png"
        />
      </pattern>
    </defs>
    <g
      className="vista-g"
      id="Group_87"
      data-name="Group 87"
      transform="translate(-389 -216)"
    >
      <image
        id="PA01.161.13E.0111_2"
        width="289"
        height="256"
        transform="translate(671 236)"
        href="../bdf-170/PA01.161.13E.0111_2.png"
      />
      <image
        id="PA01.161.13E.0111_1"
        width="221"
        height="272"
        transform="translate(389 321)"
        href="../bdf-170/PA01.161.13E.0111_1.png"
        data-url="https://shopirbal.com/pt/produto/cremalheira-de-ferro-fundido-170-175-190-200/"
        data-name="Cremalheira de Ferro Fundido 170-175-190-200"
      />
      <image
        id="PA01.161.13E.0111_8"
        width="421"
        height="533"
        transform="translate(872 373)"
        href="../bdf-170/PA01.161.13E.0111_8.png"
      />
      <image
        id="PA01.161.13E.0111_3"
        width="23"
        height="27"
        transform="translate(1015 409)"
        href="../bdf-170/PA01.161.13E.0111_3.png"
        data-url="https://shopirbal.com/pt/produto/interruptor-b1/"
        data-name="Interruptor B1"
      />
      <image
        id="PA01.161.13E.0111_5"
        width="65"
        height="53"
        transform="translate(771 544)"
        href="../bdf-170/PA01.161.13E.0111_5.png"
        data-url="https://shopirbal.com/pt/produto/rolamento-rl-6207-2rs/"
        data-name="Rolamento do Cubo do Balde RL 6207 2RS"
      />
      <image
        id="PA01.161.13E.0111_6"
        width="30"
        height="33"
        transform="translate(744 623)"
        href="../bdf-170/PA01.161.13E.0111_6.png"
      />
      <image
        id="PA01.161.13E.0111_7"
        width="177"
        height="216"
        transform="translate(552 661)"
        href="../bdf-170/PA01.161.13E.0111_7.png"
        data-url="https://shopirbal.com/pt/produto/volante-laranja/"
        data-name="Volante Laranja"
      />
      <image
        id="PA01.161.13E.0111_4"
        width="61"
        height="43"
        transform="translate(1021 558)"
        href="../bdf-170/PA01.161.13E.0111_4.png"
        data-url="https://shopirbal.com/pt/produto/pinhao-carreto-b1-150-cavilha/"
        data-name="Pinhão/Carreto B1-150 + Cavilha"
      />
      <image
        id="PA01.161.13E.0111_10"
        width="43"
        height="29"
        transform="translate(1312 611)"
        href="../bdf-170/PA01.161.13E.0111_10.png"
      />
      <image
        id="PA01.161.13E.0111_9"
        width="49"
        height="59"
        transform="translate(1355 791)"
        href="../bdf-170/PA01.161.13E.0111_9.png"
        data-url="https://shopirbal.com/pt/produto/roda-macica-160-com-freio/"
        data-name="Roda Maciça Ø160 com Freio"
      />
      <image
        id="PA01.161.13E.0111_11"
        width="42"
        height="31"
        transform="translate(1206 342)"
        href="../bdf-170/PA01.161.13E.0111_11.png"
        data-url="https://shopirbal.com/pt/produto/rolamento-6303-2rs/"
        data-name="Rolamento do Lado do Pinhão RL 6303 2RS"
      />
      <image
        id="PA01.161.13E.0111_14"
        width="48"
        height="88"
        transform="translate(1264 219)"
        href="../bdf-170/PA01.161.13E.0111_14.png"
        data-url="https://shopirbal.com/pt/produto/correia-6pj-636-elastica/"
        data-name="Correia 6PJ 636 Elástica"
      />
      <image
        id="PA01.161.13E.0111_12"
        width="72"
        height="62"
        transform="translate(1296 333)"
        href="../bdf-170/PA01.161.13E.0111_12.png"
      />
      <image
        id="PA01.161.13E.0111_15"
        width="137"
        height="182"
        transform="translate(1394 216)"
        href="../bdf-170/PA01.161.13E.0111_15.png"
        data-url="https://shopirbal.com/pt/produto/tampa-do-motor-plastica-laranja/"
        data-name="Tampa do Motor Plástica Laranja"
      />
      <rect
        id="PA01.161.13E.0111_18"
        width="51"
        height="59"
        transform="translate(1438 511)"
        fill="url(#pattern)"
        data-url="https://shopirbal.com/pt/produto/condensador-700wt/"
        data-name="Condensador 700W"
      />
      <rect
        id="PA01.161.13E.0111_17"
        width="80"
        height="93"
        transform="translate(1568 540)"
        fill="url(#pattern-2)"
        data-url="https://shopirbal.com/pt/produto/ventoinha-para-motor-700w/"
        data-name="Ventoinha para Motor 700W"
      />
      <rect
        id="PA01.161.13E.0111_13"
        width="104"
        height="106"
        transform="translate(1411 626)"
        fill="url(#pattern-3)"
        data-url="https://shopirbal.com/pt/produto/motor-ele-700w/"
        data-name="Motor ELE 700W"
      />
    </g>
  </svg>
)
